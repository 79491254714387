
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900,900i|Josefin+Sans:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500&display=swap");
@import url('https://fonts.googleapis.com/css?family=Fira+Mono:400,500,700&display=swap');
@import "animations";
@import "mq";
@import "product";
@import "fonts";

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 65%;
}

body {
  margin: 0;
  padding: 0;
  // font-family: 'Noto Sans', sans-serif;
  font-family: 'Fira Mono', monospace; 
  font-family: 'Fira Mono', monospace; 
  font-family: 'Fira Mono', monospace; 
  font-weight: 300;
  color: black;
  background-color: white;
}

h2 {
  font-size: 30px;
  // font-family: 'lackregular', sans-serif;
  // font-family: 'Playfair Display', sans-serif;
  font-weight: 900;
  color: black;
}

p {
  font-size: 20px;
}

a {
  color: white;
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

h1 {
  font-weight: 300;
  margin: 0 0 15px;
  font-size: 3rem;
}

/* BASE APP
 * ============================== */
.App {
  // overflow-y: hidden;
}

.App__nav {
  width: 100%;
  list-style: none;
}

.App__customer-actions {
  float: left;
  padding: 10px;
}

.App__title {
  padding: 80px 20px;
  text-align: center;
}

.Product-wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 40px auto 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px 20px;

  :nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: span 3;
  }

  :nth-child(5n + 0) {
    grid-column-start: auto;
    grid-column-end: span 2;
    grid-row-start: auto;
    grid-row-end: span 2;
  }

  :nth-child(9n + 0) {
    // grid-column-start: 2;
    grid-column-end: span 2;
    // grid-row-start: 3;
    grid-row-end: span 2;
  }

  .Product {
    @include media-query($medium-down) {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: auto;
      grid-row-end: span 1;
    }
  }
}

.App__view-cart-wrapper {
  float: right;
}

.App__view-cart {
  font-size: 15px;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
}

.button {
  background-color: black;
  color: white;
  border: none;
  font-size: 2rem;
  padding: 10px 27px;
  cursor: pointer;
  border-radius: 30px;
  font-weight: bold;
  display: block;
  margin-top: 25px;
  transition: all 0.5s ease;
  border: 1px solid black;

  &:hover {
    background-color: transparent;
    border: 1px solid black;
  }
}

.button:disabled {
  background: #bfbfbf;
  cursor: not-allowed;
}

.login {
  font-size: 1.2rem;
  color: #b8b8b8;
  cursor: pointer;
}

.login:hover {
  color: white;
}

.Flash__message-wrapper {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  pointer-events: none;
  z-index: 227;
  left: 50%;
  transform: translateX(-50%);
}

.Flash__message {
  background: rgba(0, 0, 0, 0.88);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: black;
  cursor: default;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  pointer-events: auto;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  padding: 10px 20px;
  margin: 0;
}

.Flash__message--open {
  display: flex;
}

.prodPrice {
  font-weight: bold;
  border-bottom: 1px solid;
  padding-bottom: 5px;
  display: inline-block;
  color: black;
}

/* CART
 * ============================== */
.Cart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 350px;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e5e5;
  transform: translateX(calc(100% + 60px));
  transition: transform 0.6s ease-in-out;
  z-index: 11;
}


.Cart--open {
  transform: translateX(0);
}

.Cart__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: white;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
  color: white;
}

.Cart__header {
  padding: 20px;
  border-bottom: 1px solid white;
  flex: 0 0 auto;
  display: inline-block;
  color: white;
}

.Cart__line-items {
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
}

.Cart__footer {
  padding: 20px;
  color: white;
  border-top: 1px solid white;
  flex: 0 0 auto;
}

.Cart__checkout {
  margin-top: 20px;
  display: block;
  width: 100%;
  background: black;
}

.Cart-info {
  padding: 15px 20px 10px;
}

.Cart-info__total {
  float: left;
  text-transform: uppercase;
  color: white;
}

.Cart-info__small {
  font-size: 11px;
  color: white;
}

.Cart-info__pricing {
  float: right;
  color: white;
}

.pricing {
  margin-left: 5px;
  font-size: 16px;
  color: white;
}

/* LINE ITEMS
 * ============================== */
.Line-item {
  margin-bottom: 20px;
  overflow: hidden;
  backface-visibility: visible;
  min-height: 95px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.Line-item__img {
  width: 95px;
  height: 95px;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
}

.Line-item__content {
  width: 100%;
  padding-left: 75px;
}

.Line-item__content-row {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
  text-align: right;
}

.Line-item__variant-title {
  float: right;
  font-weight: bold;
  font-size: 11px;
  line-height: 17px;
  color: #767676;
}

.Line-item__title {
  color: white;
  font-size: 19px;
  font-weight: 400;
}

.Line-item__price {
  line-height: 23px;
  float: right;
  font-weight: bold;
  font-size: 17px;
  margin-right: 40px;
  color: white;
}

.Line-item__quantity-container {
  border: 1px solid #767676;
  float: left;
  border-radius: 3px;
}

.Line-item__quantity-update {
  color: #767676;
  display: block;
  float: left;
  height: 21px;
  line-height: 16px;
  font-family: monospace;
  width: 25px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.Line-item__quantity-update-form {
  display: inline;
}

.Line-item__quantity {
  color: #58585b;
  width: 38px;
  height: 21px;
  line-height: 23px;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border-left: 1px solid #767676;
  border-right: 1px solid #767676;
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
}

.Line-item__remove {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 20px;
  top: -4px;
  opacity: 0.5;
  color: white;
}

.Line-item__remove:hover {
  opacity: 1;
  cursor: pointer;
}

/* PRODUCTS
 * ============================== */
.Product {
  a {
    display: block;
    height: 100%;

    img {
      object-fit: cover;
      transform: scale(1);
      transition: all 1s ease;
    }
  }

  :hover {
    img {
      transform: scale(0.97);
    }
  }
}

.Product__title {
  font-size: 1.6rem;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  color: black;
}

.Product__price {
  display: inline-block;
  font-size: 1.5rem;
  margin-bottom: 0.4rem;
  margin-right: 0.3rem;
  color: black;
}

.soldOut-text {
  display: inline-block;
  font-size: 1.5rem;
  display: none;
  color: black;
}

.isSoldOut .soldOut-text {
  display: initial;
}

.isSoldOut .Product__price {
  text-decoration: line-through;
}

.Product__option {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.Product__quantity {
  display: block;
  width: 15%;
  margin-bottom: 10px;
}

.CustomerAuth {
  background: #2a2c2e;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  padding: 0 0 65px;
  top: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  transition: opacity 150ms;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  position: fixed;
}

.CustomerAuth--open {
  display: block;
}

.CustomerAuth__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.CustomerAuth__body {
  padding: 130px 30px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  position: relative;
}

.CustomerAuth__heading {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 15px;
}

.CustomerAuth__credential {
  display: block;
  position: relative;
  margin-bottom: 15px;
  border-radius: 3px;
}

.CustomerAuth__input {
  height: 60px;
  padding: 24px 10px 20px;
  border: 0px;
  font-size: 18px;
  background: #fff;
  width: 100%;
}

.CustomerAuth__submit {
  float: right;
}

.error {
  display: block;
  font-size: 15px;
  padding: 10px;
  position: relative;
  min-height: 2.64286em;
  background: #fbefee;
  color: red;
}

.collectionNav {
  position: fixed;
  top: 30px;
  left: 30px;

  @include media-query($medium-down) {
    left: 15px;
    top: 20px;
  }

  z-index: 10;

  ul,
  span {
    position: relative;
    z-index: 2;
  }

  li,
  span {
    color: black;
    margin: 5px 0;
    font-size: 22px;

    @include media-query($medium-down) {
      font-size: 18px;
    }

    font-weight: bold;

    a {
      color: black;
    }
  }

  span {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.navOpen {
      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      margin-left: 10px;
      width: 25px;
      transition: all 0.5s ease;
    }
  }
}

#menuCont {
  position: fixed;
  top: 0;
  left: -30px;
  width: 280px;
  opacity: 0;
}

.mainArea {
  min-height: 85vh;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 84px 20px 0;
}

.bg-white {
  background-color: white;

  .cart-count span {
    color: black;
  }
}

.instagramWrap {
  margin-top: 100px;

  @include media-query($mobile) {
    margin-top: 20px;
  }

  .link {
    text-align: left;
    display: inline-flex;
    margin-bottom: 10px;
    margin-top: 80px;
    align-items: center;
    transition: opacity 0.5s ease;
    transform: translateY(0);

    span {
      transition: all 0.5s ease;
      opacity: 0;
      max-width: 0;
    }

    &:hover {

      // transform: translateY(-20px);
      span {
        max-width: 400px;
        opacity: 1;
      }
    }

    svg {
      width: 80px;
    }

    span {
      font-size: 22px;
    }
  }
}

.instagramGrid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  padding: 0 20px;

  @include media-query($medium-down) {
    grid-template-columns: repeat(4, 1fr);
  }

  a {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.cart-count {
  color: black;
  cursor: pointer;
  font-size: 22px;

  @include media-query($medium-down) {
    font-size: 18px;
  }

  font-weight: bold;

  span {
    border-radius: 50%;
    background: white;
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
    line-height: 19px;
    transition: all 0.5s ease;
    line-height: 23px;
  }

  &:hover {
    span {
      width: 30px;
      height: 30px;
    }
  }
}

#shape-defs {
  display: none;
}

#container {
  position: fixed;
  width: 430px;
  left: -60px;
  top: -40px;
  pointer-events: none;

  path {
    fill: black;
  }
}

body {
  transition: 0.8s ease background-color;
}

footer a {
  color: white;
}

footer ul {
  font-size: 26px;
  margin: 30px 0 30px 15px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @include media-query($medium-down) {
    flex-direction: column;

    li {
      margin-bottom: 15px;
    }
  }

  li {
    margin-right: 30px;
  }
}

.About {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 20px;

  @include media-query($medium-down) {
    grid-template-columns: 1fr;
  }
}

.drag {
  cursor: move;
  min-height: 600px;

  @include media-query($small-down) {
    min-height: initial;
  }
}

.inLink {
  text-decoration: underline;
}

.scrollable {
  display: flex;
  flex-direction: row;

  img {
    margin-bottom: 10px;
  }
}

.sticky {
  position: sticky;
  height: 100vh;
  top: 0;

  @include media-query($mobile) {
    height: auto;
  }
}

.Policies {
  max-width: 800px;
  margin: 30px auto;
}

.smile-loader {
  box-sizing: border-box;
  position: absolute;
  top: calc(50vh - 200px);
  left: calc(50vw - 50px);
  width: 100px;
  height: 100px;
  animation: smile-loop 1.8s infinite;
}

@keyframes smile-loop {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Home {
  width: 100%;
  position: absolute;
  min-height: 85vh;
  max-height: 85vh;
  max-width: 100vw;
  margin-bottom: 120px;
  top: 12vh;

  .drag {
    width: 300px;
    position: absolute;
    min-height: initial;
    // min-height: inherit;
    // display: flex;
    // align-items: center;
  }

  .img1 {
    left: 12vw;
    top: 60px;
    width: 400px;

    @include media-query($mobile) {
      display: none;
    }
  }

  .img2 {
    right: 10vw;
    bottom: 80px;
    width: 340px;

    @include media-query($mobile) {
      right: 3vw;
      top: 80px;
      width: 300px;
    }
  }

  .img3 {
    width: 520px;
    left: 35vw;
    bottom: 0px;

    @include media-query($mobile) {
      left: 20px;
      width: 250px;
      bottom: 15px;
    }
  }

  .svg1 {
    width: 100px;
    right: 12vw;
    bottom: 10px;

    @include media-query($mobile) {
      display: none;
    }
  }

  .svg2 {
    width: 160px;
    left: 55vw;
    top: 170px;

    @include media-query($mobile) {
      left: 15px;
      top: 0;
      width: 130px;
    }
  }

  .svg3 {
    width: 100px;
    bottom: 0px;
    left: 10vw;

    @include media-query($mobile) {
      right: 10px;
      left: initial;
    }
  }
}

.soldOut {
  font-size: 22px;
  line-height: 1.6;
}

.nav-link {
  transition: transform 0.5s ease;
  transform: translateX(0);

  &:hover {
    transform: translateX(20px);
  }
}

.footer-nav-link {
  transition: transform 0.5s ease;
  transform: translateY(0);

  &:hover {
    transform: translateY(-10px);
  }
}