
@font-face {
    font-family: 'lackitalic';
    src: url('./../fonts/Lack-Italic.woff2') format('woff2'),
         url('./../fonts/Lack-Italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'lackregular';
    src: url('./../fonts/Lack-Regular.woff2') format('woff2'),
         url('./../fonts/Lack-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

