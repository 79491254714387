@import url(https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900,900i|Josefin+Sans:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Fira+Mono:400,500,700&display=swap);
.arzel_logo {
  width: 220px;
  margin-left: 60px;
  fill: black; }
  @media only screen and (max-width: 575px) {
    .arzel_logo {
      width: 150px; } }

.mainHeader {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 10px 30px; }
  @media only screen and (max-width: 61.7875em) {
    .mainHeader {
      padding: 10px 15px; } }

.fade-appear {
  opacity: 0;
  z-index: 1; }

.fade-appear.fade-appear-active {
  opacity: 1;
  -webkit-transition: opacity 1000ms linear;
  transition: opacity 1000ms linear; }

.collectionNav .fade-appear {
  -webkit-transition-delay: .35s;
          transition-delay: .35s; }

.fade-enter {
  opacity: 0;
  z-index: 1; }

.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 5000ms linear 5000ms;
  transition: opacity 5000ms linear 5000ms; }

.fade-exit {
  opacity: 1; }

.fade-exit.fade-exit-active {
  opacity: 0;
  -webkit-transition: opacity 5000ms linear;
  transition: opacity 5000ms linear; }

.fade-exit-done {
  opacity: 0; }

.ProductView {
  width: 100%;
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr; }
  @media only screen and (max-width: 575px) {
    .ProductView .images {
      display: flex;
      flex-direction: column;
      height: 60vh;
      overflow-y: scroll;
      width: 100vw;
      position: absolute;
      -webkit-transform: translateX(-30px);
              transform: translateX(-30px); }
      .ProductView .images img {
        max-width: 100%; } }
  .ProductView img {
    max-width: 650px; }
    @media only screen and (max-width: 61.7875em) {
      .ProductView img {
        max-width: 100%; } }
  .ProductView .col-left {
    grid-column-start: 1;
    grid-column-end: 3; }
    @media only screen and (max-width: 61.7875em) {
      .ProductView .col-left {
        grid-column-end: 4; } }
  @media only screen and (max-width: 61.7875em) {
    .ProductView .col-right {
      grid-column-start: 1;
      grid-column-end: 4; } }
  @media only screen and (max-width: 575px) {
    .ProductView .col-right {
      margin-top: 65vh; } }
  .ProductView .thumbnails {
    display: flex;
    width: 115px;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    position: -webkit-sticky;
    position: sticky;
    top: 90px;
    cursor: pointer;
    margin-right: 10px;
    height: 100%; }
    @media only screen and (max-width: 61.7875em) {
      .ProductView .thumbnails {
        display: none; } }
    .ProductView .thumbnails .thumbImage {
      width: 100px;
      height: 100px;
      object-fit: cover;
      margin: 0 15px 15px 0; }

@media only screen and (max-width: 575px) {
  .Product__buy {
    margin-bottom: 30px; } }

@font-face {
  font-family: 'lackitalic';
  src: url(/static/media/Lack-Italic.4cff5233.woff2) format("woff2"), url(/static/media/Lack-Italic.db055f1e.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'lackregular';
  src: url(/static/media/Lack-Regular.2ba06ebe.woff2) format("woff2"), url(/static/media/Lack-Regular.21ba0040.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

*,
*:before,
*:after {
  box-sizing: border-box; }

html {
  font-size: 65%; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Mono', monospace;
  font-family: 'Fira Mono', monospace;
  font-family: 'Fira Mono', monospace;
  font-weight: 300;
  color: black;
  background-color: white; }

h2 {
  font-size: 30px;
  font-weight: 900;
  color: black; }

p {
  font-size: 20px; }

a {
  color: white;
  text-decoration: none; }

img {
  display: block;
  max-width: 100%;
  max-height: 100%; }

h1 {
  font-weight: 300;
  margin: 0 0 15px;
  font-size: 3rem; }

/* BASE APP
 * ============================== */
.App__nav {
  width: 100%;
  list-style: none; }

.App__customer-actions {
  float: left;
  padding: 10px; }

.App__title {
  padding: 80px 20px;
  text-align: center; }

.Product-wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 40px auto 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px 20px; }
  .Product-wrapper :nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: span 3; }
  .Product-wrapper :nth-child(5n + 0) {
    grid-column-start: auto;
    grid-column-end: span 2;
    grid-row-start: auto;
    grid-row-end: span 2; }
  .Product-wrapper :nth-child(9n + 0) {
    grid-column-end: span 2;
    grid-row-end: span 2; }
  @media only screen and (max-width: 61.7875em) {
    .Product-wrapper .Product {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row-start: auto;
      grid-row-end: span 1; } }

.App__view-cart-wrapper {
  float: right; }

.App__view-cart {
  font-size: 15px;
  border: none;
  background: none;
  cursor: pointer;
  color: white; }

.button {
  background-color: black;
  color: white;
  border: none;
  font-size: 2rem;
  padding: 10px 27px;
  cursor: pointer;
  border-radius: 30px;
  font-weight: bold;
  display: block;
  margin-top: 25px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: 1px solid black; }
  .button:hover {
    background-color: transparent;
    border: 1px solid black; }

.button:disabled {
  background: #bfbfbf;
  cursor: not-allowed; }

.login {
  font-size: 1.2rem;
  color: #b8b8b8;
  cursor: pointer; }

.login:hover {
  color: white; }

.Flash__message-wrapper {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  pointer-events: none;
  z-index: 227;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.Flash__message {
  background: rgba(0, 0, 0, 0.88);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: black;
  cursor: default;
  display: -ms-flexbox;
  display: none;
  pointer-events: auto;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  padding: 10px 20px;
  margin: 0; }

.Flash__message--open {
  display: flex; }

.prodPrice {
  font-weight: bold;
  border-bottom: 1px solid;
  padding-bottom: 5px;
  display: inline-block;
  color: black; }

/* CART
 * ============================== */
.Cart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 350px;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e5e5;
  -webkit-transform: translateX(calc(100% + 60px));
          transform: translateX(calc(100% + 60px));
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  z-index: 11; }

.Cart--open {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.Cart__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: white;
  border: none;
  background: transparent;
  -webkit-transition: -webkit-transform 100ms ease;
  transition: -webkit-transform 100ms ease;
  transition: transform 100ms ease;
  transition: transform 100ms ease, -webkit-transform 100ms ease;
  cursor: pointer;
  color: white; }

.Cart__header {
  padding: 20px;
  border-bottom: 1px solid white;
  flex: 0 0 auto;
  display: inline-block;
  color: white; }

.Cart__line-items {
  flex: 1 0 auto;
  margin: 0;
  padding: 20px; }

.Cart__footer {
  padding: 20px;
  color: white;
  border-top: 1px solid white;
  flex: 0 0 auto; }

.Cart__checkout {
  margin-top: 20px;
  display: block;
  width: 100%;
  background: black; }

.Cart-info {
  padding: 15px 20px 10px; }

.Cart-info__total {
  float: left;
  text-transform: uppercase;
  color: white; }

.Cart-info__small {
  font-size: 11px;
  color: white; }

.Cart-info__pricing {
  float: right;
  color: white; }

.pricing {
  margin-left: 5px;
  font-size: 16px;
  color: white; }

/* LINE ITEMS
 * ============================== */
.Line-item {
  margin-bottom: 20px;
  overflow: hidden;
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  min-height: 95px;
  position: relative;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out; }

.Line-item__img {
  width: 95px;
  height: 95px;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute; }

.Line-item__content {
  width: 100%;
  padding-left: 75px; }

.Line-item__content-row {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
  text-align: right; }

.Line-item__variant-title {
  float: right;
  font-weight: bold;
  font-size: 11px;
  line-height: 17px;
  color: #767676; }

.Line-item__title {
  color: white;
  font-size: 19px;
  font-weight: 400; }

.Line-item__price {
  line-height: 23px;
  float: right;
  font-weight: bold;
  font-size: 17px;
  margin-right: 40px;
  color: white; }

.Line-item__quantity-container {
  border: 1px solid #767676;
  float: left;
  border-radius: 3px; }

.Line-item__quantity-update {
  color: #767676;
  display: block;
  float: left;
  height: 21px;
  line-height: 16px;
  font-family: monospace;
  width: 25px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center; }

.Line-item__quantity-update-form {
  display: inline; }

.Line-item__quantity {
  color: #58585b;
  width: 38px;
  height: 21px;
  line-height: 23px;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border-left: 1px solid #767676;
  border-right: 1px solid #767676;
  display: block;
  float: left;
  padding: 0;
  border-radius: 0; }

.Line-item__remove {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 20px;
  top: -4px;
  opacity: 0.5;
  color: white; }

.Line-item__remove:hover {
  opacity: 1;
  cursor: pointer; }

/* PRODUCTS
 * ============================== */
.Product a {
  display: block;
  height: 100%; }
  .Product a img {
    object-fit: cover;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: all 1s ease;
    transition: all 1s ease; }

.Product :hover img {
  -webkit-transform: scale(0.97);
          transform: scale(0.97); }

.Product__title {
  font-size: 1.6rem;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  color: black; }

.Product__price {
  display: inline-block;
  font-size: 1.5rem;
  margin-bottom: 0.4rem;
  margin-right: 0.3rem;
  color: black; }

.soldOut-text {
  display: inline-block;
  font-size: 1.5rem;
  display: none;
  color: black; }

.isSoldOut .soldOut-text {
  display: inline;
  display: initial; }

.isSoldOut .Product__price {
  text-decoration: line-through; }

.Product__option {
  display: block;
  width: 100%;
  margin-bottom: 10px; }

.Product__quantity {
  display: block;
  width: 15%;
  margin-bottom: 10px; }

.CustomerAuth {
  background: #2a2c2e;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  padding: 0 0 65px;
  top: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  -webkit-transition: opacity 150ms;
  transition: opacity 150ms;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  position: fixed; }

.CustomerAuth--open {
  display: block; }

.CustomerAuth__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  -webkit-transition: -webkit-transform 100ms ease;
  transition: -webkit-transform 100ms ease;
  transition: transform 100ms ease;
  transition: transform 100ms ease, -webkit-transform 100ms ease;
  cursor: pointer; }

.CustomerAuth__body {
  padding: 130px 30px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  position: relative; }

.CustomerAuth__heading {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 15px; }

.CustomerAuth__credential {
  display: block;
  position: relative;
  margin-bottom: 15px;
  border-radius: 3px; }

.CustomerAuth__input {
  height: 60px;
  padding: 24px 10px 20px;
  border: 0px;
  font-size: 18px;
  background: #fff;
  width: 100%; }

.CustomerAuth__submit {
  float: right; }

.error {
  display: block;
  font-size: 15px;
  padding: 10px;
  position: relative;
  min-height: 2.64286em;
  background: #fbefee;
  color: red; }

.collectionNav {
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 10; }
  @media only screen and (max-width: 61.7875em) {
    .collectionNav {
      left: 15px;
      top: 20px; } }
  .collectionNav ul,
  .collectionNav span {
    position: relative;
    z-index: 2; }
  .collectionNav li,
  .collectionNav span {
    color: black;
    margin: 5px 0;
    font-size: 22px;
    font-weight: bold; }
    @media only screen and (max-width: 61.7875em) {
      .collectionNav li,
      .collectionNav span {
        font-size: 18px; } }
    .collectionNav li a,
    .collectionNav span a {
      color: black; }
  .collectionNav span {
    display: flex;
    align-items: center;
    cursor: pointer; }
    .collectionNav span.navOpen svg {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    .collectionNav span svg {
      margin-left: 10px;
      width: 25px;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease; }

#menuCont {
  position: fixed;
  top: 0;
  left: -30px;
  width: 280px;
  opacity: 0; }

.mainArea {
  min-height: 85vh;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 84px 20px 0; }

.bg-white {
  background-color: white; }
  .bg-white .cart-count span {
    color: black; }

.instagramWrap {
  margin-top: 100px; }
  @media only screen and (max-width: 575px) {
    .instagramWrap {
      margin-top: 20px; } }
  .instagramWrap .link {
    text-align: left;
    display: inline-flex;
    margin-bottom: 10px;
    margin-top: 80px;
    align-items: center;
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
    .instagramWrap .link span {
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease;
      opacity: 0;
      max-width: 0; }
    .instagramWrap .link:hover span {
      max-width: 400px;
      opacity: 1; }
    .instagramWrap .link svg {
      width: 80px; }
    .instagramWrap .link span {
      font-size: 22px; }

.instagramGrid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  padding: 0 20px; }
  @media only screen and (max-width: 61.7875em) {
    .instagramGrid {
      grid-template-columns: repeat(4, 1fr); } }
  .instagramGrid a {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    .instagramGrid a svg {
      width: 100%;
      height: 100%; }

.cart-count {
  color: black;
  cursor: pointer;
  font-size: 22px;
  font-weight: bold; }
  @media only screen and (max-width: 61.7875em) {
    .cart-count {
      font-size: 18px; } }
  .cart-count span {
    border-radius: 50%;
    background: white;
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
    line-height: 19px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    line-height: 23px; }
  .cart-count:hover span {
    width: 30px;
    height: 30px; }

#shape-defs {
  display: none; }

#container {
  position: fixed;
  width: 430px;
  left: -60px;
  top: -40px;
  pointer-events: none; }
  #container path {
    fill: black; }

body {
  -webkit-transition: 0.8s ease background-color;
  transition: 0.8s ease background-color; }

footer a {
  color: white; }

footer ul {
  font-size: 26px;
  margin: 30px 0 30px 15px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center; }
  @media only screen and (max-width: 61.7875em) {
    footer ul {
      flex-direction: column; }
      footer ul li {
        margin-bottom: 15px; } }
  footer ul li {
    margin-right: 30px; }

.About {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 20px; }
  @media only screen and (max-width: 61.7875em) {
    .About {
      grid-template-columns: 1fr; } }

.drag {
  cursor: move;
  min-height: 600px; }
  @media only screen and (max-width: 47.97em) {
    .drag {
      min-height: 0;
      min-height: initial; } }

.inLink {
  text-decoration: underline; }

.scrollable {
  display: flex;
  flex-direction: row; }
  .scrollable img {
    margin-bottom: 10px; }

.sticky {
  position: -webkit-sticky;
  position: sticky;
  height: 100vh;
  top: 0; }
  @media only screen and (max-width: 575px) {
    .sticky {
      height: auto; } }

.Policies {
  max-width: 800px;
  margin: 30px auto; }

.smile-loader {
  box-sizing: border-box;
  position: absolute;
  top: calc(50vh - 200px);
  left: calc(50vw - 50px);
  width: 100px;
  height: 100px;
  -webkit-animation: smile-loop 1.8s infinite;
          animation: smile-loop 1.8s infinite; }

@-webkit-keyframes smile-loop {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes smile-loop {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.Home {
  width: 100%;
  position: absolute;
  min-height: 85vh;
  max-height: 85vh;
  max-width: 100vw;
  margin-bottom: 120px;
  top: 12vh; }
  .Home .drag {
    width: 300px;
    position: absolute;
    min-height: 0;
    min-height: initial; }
  .Home .img1 {
    left: 12vw;
    top: 60px;
    width: 400px; }
    @media only screen and (max-width: 575px) {
      .Home .img1 {
        display: none; } }
  .Home .img2 {
    right: 10vw;
    bottom: 80px;
    width: 340px; }
    @media only screen and (max-width: 575px) {
      .Home .img2 {
        right: 3vw;
        top: 80px;
        width: 300px; } }
  .Home .img3 {
    width: 520px;
    left: 35vw;
    bottom: 0px; }
    @media only screen and (max-width: 575px) {
      .Home .img3 {
        left: 20px;
        width: 250px;
        bottom: 15px; } }
  .Home .svg1 {
    width: 100px;
    right: 12vw;
    bottom: 10px; }
    @media only screen and (max-width: 575px) {
      .Home .svg1 {
        display: none; } }
  .Home .svg2 {
    width: 160px;
    left: 55vw;
    top: 170px; }
    @media only screen and (max-width: 575px) {
      .Home .svg2 {
        left: 15px;
        top: 0;
        width: 130px; } }
  .Home .svg3 {
    width: 100px;
    bottom: 0px;
    left: 10vw; }
    @media only screen and (max-width: 575px) {
      .Home .svg3 {
        right: 10px;
        left: auto;
        left: initial; } }

.soldOut {
  font-size: 22px;
  line-height: 1.6; }

.nav-link {
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  .nav-link:hover {
    -webkit-transform: translateX(20px);
            transform: translateX(20px); }

.footer-nav-link {
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform: translateY(0);
          transform: translateY(0); }
  .footer-nav-link:hover {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }

