.ProductView {
  width: 100%;
  display: grid;
  grid-column-gap: 30px;
   grid-template-columns: 1fr 1fr 1fr;

   .images{
  @include media-query($mobile) {
    display: flex;
    flex-direction: column;
    height: 60vh;
    overflow-y: scroll;
    width: 100vw;
    position: absolute;
    transform: translateX(-30px);

    img {
      max-width: 100%;
    }
  }
}

 img{  
   max-width: 650px;
    @include media-query($medium-down) {
    max-width: 100%;
 }
}

 .col-left {
  grid-column-start: 1;
  grid-column-end: 3;

  @include media-query($medium-down) {
   grid-column-end: 4;
  }
 }

 .col-right {
    @include media-query($medium-down) {
      grid-column-start: 1;
   grid-column-end: 4;
  }
   @include media-query($mobile) {
    margin-top: 65vh;
  }
  
 }

 .thumbnails {
  display: flex;
  width: 115px;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  position: sticky;
  top: 90px;
  cursor: pointer;
  margin-right: 10px; 
  height: 100%;

   @include media-query($medium-down) {
    display:none;
  }

  .thumbImage {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin: 0 15px 15px 0;
  }
 }

 .images {

 }
}


.Product__buy {
   @include media-query($mobile) {
    margin-bottom: 30px;
  }
}