@import 'mq';

.arzel_logo {
  width: 220px;
  margin-left: 60px;
  fill: black;

  @include media-query($mobile) {
    width: 150px;
  }
}

.mainHeader {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 10px 30px;

  @include media-query($medium-down) {
    padding: 10px 15px;
  }
}
